<template>
  <div class="page page--bottom-indent page__delegation delegation">
    <delegation-detail @toggle-modal="toggleModal" />
    <modal-delegation-prolongation
      :is-show="showModals.ModalDelegationProlongation"
      :delegation="updatedDelegation"
      @toggle-modal="toggleModal"
    />
  </div>
</template>

<script>
import DelegationDetail from '@/components/delegation-detail';
import ModalDelegationProlongation from '@/components/modals/modal-delegation-prolongation';

export default {
  name: 'Delegation',
  components: {
    DelegationDetail,
    ModalDelegationProlongation,
  },
  data() {
    return {
      showModals: {
        ModalDelegationProlongation: false,
      },
      updatedDelegation: null,
    };
  },
  methods: {
    toggleModal(payload) {
      this.showModals[payload.modalName] = payload.modalState;
      this.updatedDelegation = payload.delegation || null;
    },
  },
};
</script>
